import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import { Link } from "gatsby"
import { CameraIcon } from '@heroicons/react/solid'

const OurCompany = ({ data }) => {
  const { bookImage, teamImage } = data;

  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Sell Your Home Or Land For Cash Sacramento | 916-237-9457'}
        description={'Sell Your Home Or Land For Cash Sacramento is based in Sacramento and focuses on helping property owners who need to sell fast for a fair price. Just call us.'}
        keywords={''}
      />

      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">
            <h1>Sell Your Home or Land For Cash in Sacramento – Learn More About Us!</h1>

            <Img
              className="rounded-lg shadow-lg object-cover object-center md:pr-8"
              fluid={teamImage.childImageSharp.fluid}
            />
            <figcaption className="mt-3 flex text-sm text-gray-500">
              <CameraIcon className="flex-none w-5 h-5 text-gray-400" />
              <span className="ml-2">Sell Your Home Or Land For Cash Sacramento Team</span>
            </figcaption>

            <p>Hi, my name is <strong>Brandon Evans</strong>, and I am the owner of <strong>Sell Your Home Or Land For Cash Sacramento</strong>. I have years of experience purchasing houses and land here in the Sacramento region.&nbsp;</p>
            <p>Past sellers have told me that I am <strong>polite, easy to work with, and accommodating</strong> to their individual needs.</p>
            <p>I’m <strong>a local, experienced real estate professional</strong>. I partner with a team of licensed contractors who can tackle any residential remodeling project or repair. Because of this partnership, my offers are often the most competitive.</p>
            <p>The many people I have bought property from have really appreciated the transparency and protection I offer. All sales are done using California-approved real estate contracts, and I use a neutral third-party escrow company to handle closings.</p>
            <p><strong>Property owners really appreciate the professionalism I bring… plus the ability to pay with cash and close quickly.</strong></p>
            <p>People often need to sell their home or land quickly and painlessly, without listing on the market with a realtor, for a variety of practical reasons. Some owners do not want an agent’s commission eating into their profits. Others don’t want to wait months to close. And many just want to sell As-Is without spending time or money on repairs or cleanup.</p>
            <p><strong>Whatever your needs are, I can customize an offer that works for you.</strong></p>
            <p>I can take care of many things that come up. I can clean out the house, evict tenants, or handle repairs – all after closing. If you need to rent back for a bit or need to close this week, I’ve got you covered. I'm here to help.</p>
            <p>Please feel free to call or email your property address and a time to show it to me that works for you. I only need to see your home or land once to make you a fair, competitive as-is offer. I promise to be a pleasure to work with throughout the process and look forward to meeting you.</p>

            <h3>Sell Your Home or Land For Cash in Sacramento!</h3>
            <h4><strong>Give Us A Call Today To Learn More! 916-237-9457</strong></h4>
            <h3>Learn More About Selling Your Sacramento Property To A Professional Buyer!</h3>

            <div className="wp-block-image">
              <figure className="float-left">
                <Link to={'/go/selling-to-a-professional/'}>
                  <Img
                    className="rounded-lg shadow-lg object-cover object-center mr-8"
                    fixed={bookImage.childImageSharp.fixed}
                    width={80}
                    alt="Sell Your Home Or Land For Cash Sacramento is a professional property buyer in Sacramento, CA."
                  />
                </Link>
              </figure>
            </div>

            <p>We’ve created this <strong><Link to={'/go/selling-to-a-professional/'}>FREE Guide</Link></strong> to help local property owners learn more about selling directly. We break down the pros and cons so you can decide what’s best for your situation.</p>
            <p>Download the guide, then reach out to our team to run the numbers on your property! There’s never any cost, hassle, or pressure to sell! Feel free to <a href="/contact-us/">Contact Sell Your Home Or Land For Cash Sacramento</a> anytime if you have questions about who we are, how we work, or what we can offer you! <strong>916-237-9457</strong></p>
          </div>

          <div className="w-full md:w-1/3 md:pl-4">
            <div className="rounded-xl mb-2 shadow bg-white mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p className="text-gray-600 mb-2 leading-6 mt-1">We buy houses and land in any condition — no commissions, no fees, and no obligation. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>
              <div className="p-8 pt-4">
                <ContactForm stacked={true} hideLabels={true} showPlaceholders={true} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks />
    </Layout>
  );
};

export default OurCompany;

export const pageQuery = graphql`
  query CompanyPageQuery {
    bookImage: file(relativePath: { eq: "selling-to-a-professional.jpeg" }) {
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed
          src
        }
      }
    }
    teamImage: file(relativePath: { eq: "team-pic.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          src
        }
      }
    }
  }
`;
